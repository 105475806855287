<template>
  <div class="paymentH5">
    <div class="failure" v-if="failure">
      <img class="icon" src="@/assets/image/payment/payment_failure.png" alt="">
      <div class="hint">
        订单已失效<br />如有问题请联系<span>400-040-2013或400-030-2013</span>
      </div>
    </div>
    <div class="succeed" v-else-if="succeed">
      <img class="icon" src="@/assets/image/payment/payment_sucdceed.png" alt="">
      <div class="hint">
        已完成支付，请勿重新支付
      </div>
    </div>
    <div v-else-if='info' class="order">
      <div class='sum'>¥{{info.payTotal}}</div>
      <div class="info">
        <div><span>收款方：</span>山东豸信认证服务有限公司</div>
        <div><span>业务单号：</span>{{info.transNo}}</div>
      </div>
      <el-button @click="goLink" class='button'>
        立即支付
      </el-button>
    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      succeed: false,
      failure: false,
      transId: '',
      info: null
    }
  },
  created() {
    const docEl = document.documentElement
    const rem = docEl.clientWidth / 8.28
    docEl.style.fontSize = `${rem}px`
    this.transId = this.$route.query.transId || ''
    this.$api.getPayInfo({ transId: this.transId }).then(data => {
      if (data && data.code === '0') {
        // window.location.href = data.msg
        this.info = data.data
      } else if (data.code === '201') {
        this.succeed = true
      } else {
        this.failure = true
      }
    }).catch(err => {
      if (err.code === '201') {
        this.succeed = true
      } else {
        this.failure = true
      }
    })
  },
  methods: {
    goLink() {
      this.$api.getPayH5({ transId: this.transId }).then(data => {
        if (data && data.code === '0') {
          window.location.href = data.data
        } else if (data.code === '201') {
          this.succeed = true
        } else {
          this.failure = true
        }
      }).catch(err => {
        if (err.code === '201') {
          this.succeed = true
        } else {
          this.failure = true
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.paymentH5 {
  width: 100vw;
  height: 100vh;
  text-align: center;
  padding: 1.43rem 0.32rem 0;
  color: #333333;
  background-color: #fff;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  & > div {
    margin-top: 1.98rem;
    .icon {
      width: 1.14rem;
    }
    .hint {
      font-size: 0.32rem;
      margin-top: 0.46rem;
      line-height: 187.5%;
      span {
        color: #107dfc;
      }
    }
  }
  .order {
    margin-top: 0;
    .sum {
      font-size: 0.6rem;
      font-weight: bold;
      line-height: 0.84rem;
      margin-bottom: 0.72rem;
    }
    .info {
      background: #f9fbfd;
      padding: 0.21rem 0.32rem;
      color: #333333;
      font-size: 0.32rem;
      margin: 0 auto;
      margin-bottom: 1.6rem;
      line-height: 0.87rem;
      text-align: left;
      span {
        display: inline-block;
        width: 1.6rem;
        margin-right: 0.24rem;
        color: #7e7f80;
      }
    }
    ::v-deep .el-button {
      width: 6.4rem;
      line-height: 0.8rem;
      background: #107dfc;
      border-radius: 0.06rem;
      padding: 0;
      font-size: 0.32rem;
      letter-spacing: 0.03rem;
      span {
        color: #fff;
      }
    }
  }
}
</style>
